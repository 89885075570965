import React from 'react';
import Button from '@mui/material/Button';
import { Link as ScrollLink } from 'react-scroll';

function TotheFormButton() {
  return (
    <div>
      <ScrollLink
        className="one"
        to="customer-form-container"
        smooth={true}
        duration={500}
        offset={-70} // Adjust offset for fixed headers if any
      >
        <Button
          className="newButton"
          variant="contained"
          style={{
            borderRadius: 25,
            backgroundColor: '#00274C',
            color: '#FFCB05',
            padding: '10px 15px',
            fontSize: '1.2rem',
            width: '16rem',
          }}
        >
          최동차와 대화하기
        </Button>
      </ScrollLink>
    </div>
  );
}

export default TotheFormButton;
