/* eslint-disable jsx-a11y/anchor-is-valid */
// src/CustomerForm.js
import React, { useState } from 'react';
import './CustomerForm.css';
import Checkbox from '@mui/material/Checkbox';

import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useNavigate } from 'react-router-dom';
import trusty from '../Assets/trusty.jpeg';
import { collection, addDoc } from 'firebase/firestore';
import db from './firebaseConfig'; // Firestore 데이터베이스 임포트

function CustomerForm() {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [carModel, setCarModel] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleCheckboxChangeAll = (e) => {
    const checked = e.target.checked;
    setIsCheckedAll(checked);
    setIsChecked1(checked);
    setIsChecked2(checked);
    setIsChecked3(checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isCheckedAll) {
      alert('전체 동의에 클릭해 주세요.');
      return;
    }
    alert('견적 문의가 보내졌습니다. 빨리 연락드리겠습니다.');

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setSubmitted(true);
      setErrors({});
      try {
        await addDoc(collection(db, 'inquiry'), {
          name,
          phone,
          carModel,
        });
        console.log('Customer data saved to Firebase');
        setName('');
        setPhone('');
        setCarModel('');
        setIsCheckedAll(false);
        setIsChecked1(false);
        setIsChecked2(false);
        setIsChecked3(false);
      } catch (error) {
        console.error('Error saving customer data: ', error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{11}$/.test(phone)) {
      newErrors.phone = '올바른 핸드폰 번호는 11자리입니다.';
    }
    if (!carModel) newErrors.carModel = 'Car model is required';
    return newErrors;
  };

  return (
    <div className="form-section-container">
      <div className="form-section-image-container">
        <img className="csj-image" src={trusty} alt="Trusty"/>
      </div>
      <div className="customer-form-container">
        <h3>쉽고 빠른 견적문의</h3>        
        <form onSubmit={handleSubmit}>
          <div className='form-wrap'>
            <div className='form-item'>
              <div className='form-item-deep'>
                <label>이름 *</label>
                <input
                  type="text"
                  placeholder='이름을 입력하세요'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className='from-error-wrap'>
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item-deep'>
                <label>연락처 *</label>
                <input
                  type="text"
                  placeholder='휴대폰 번호(-제외)를 입력하세요'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />                
              </div>
              <div className='from-error-wrap'>                
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item-deep'>
                <label>차종 *</label>
                <input
                  type="text"
                  placeholder='차종을 입력하세요'
                  value={carModel}
                  onChange={(e) => setCarModel(e.target.value)}
                  required
                />                
              </div>
              <div className='from-error-wrap'>
                {errors.carModel && <p className="error">{errors.carModel}</p>}                
              </div>
            </div>
          </div>

          <div className='check-wrap'>
            <ul>
              <li>
                <span>
                  <Checkbox
                    type="checkbox"
                    checked={isCheckedAll}
                    onChange={handleCheckboxChangeAll}
                    icon={<PanoramaFishEyeIcon sx={{ color: 'gray' }} />}
                    checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'gray' }} />}
                  />
                  전체동의
                </span>
                <span>
                  <a className="reading" onClick={() => { navigate('/Termsconditions');}}>
                    내용보기
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <Checkbox
                    checked={isChecked1}
                    onChange={() => setIsChecked1(!isChecked1)}
                    icon={<PanoramaFishEyeIcon sx={{ color: 'gray' }} />}
                    checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'gray' }} />}
                  />
                  서비스 이용 약관 *
                </span>
              </li>
              <li>
                <span>                
                  <Checkbox
                    checked={isChecked2}
                    onChange={() => setIsChecked2(!isChecked2)}
                    icon={<PanoramaFishEyeIcon sx={{ color: 'gray' }} />}
                    checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'gray' }} />}
                  />
                  개인 정보 이용 및 활용 동의
                </span>
              </li>
              <li>
                <span>                
                  <Checkbox
                    checked={isChecked3}
                    onChange={() => setIsChecked3(!isChecked3)}
                    icon={<PanoramaFishEyeIcon sx={{ color: 'gray' }} />}
                    checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'gray' }} />}
                  />
                  개인 정보 이용 및 위탁 동의
                </span>
              </li>
            </ul>
          </div>     
          <div className='form-success-wrap'>
            {submitted && (<p className="success-message">양식이 제출됐습니다.</p>)}
          </div>      
          <button className='submit-btn' type="submit">견적 문의 하기</button>
        </form>
      </div>
    </div>
  );
}

export default CustomerForm;
