import React from 'react';
import './Benefits.css';
import TotheFormButton from './TotheFormButton';

import blackbox from '../Assets/blackbox.png';
import wind_shield from '../Assets/wind_shield.jpeg';
import engine from '../Assets/engine.jpeg';
import car_met from '../Assets/carmet.jpeg';

function Benefits() {
  const images = [
    { src: blackbox, text: '최고 사양 블랙 박스' },
    { src: wind_shield, text: '썬팅 기본 부착' },
    {
      src: engine,
      text: '맞춤 서비스\n\n유리막코팅,\n차량용 청소기,\nPPF, 주유권\n중 1종 추가제공',
    },
    { src: car_met, text: '실내 청소가 간편한\n 코일 매트' },
  ];

  return (
    <div className="bene">
      <h1>최동차가 드리는 다양한 혜택들!</h1>
      <div className="bene-image-container">
        {images.map((image, index) => (
          <div className="bene-image-wrapper" key={index}>
            <img src={image.src} alt={image.text} />
            <div className="bene-overlay">
              <div className="text">{image.text}</div>
            </div>
          </div>
        ))}
      </div>
      <TotheFormButton />
    </div>
  );
}

export default Benefits;
