import * as React from 'react';
import LeftYellowBackground from '../Assets/left-background.png';
import styled from 'styled-components';
import csj from '../Assets/csj_1.png';

const LeftBackground = {
  position: 'absolute',
  left: '-130px',
  zIndex: '-2',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1400px;
  margin: 3rem auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: left;
  
  h1 {
    font-size: 2.2rem;
    color: "#00274C",
    line-height: 2rem;
  }

  p {
    font-size: 1.5rem;
    color: "#00274C",
    line-height: 40px;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1080px;
    border-radius: 8px;
    box-shadow: 10px 5px 5px gray;
  }
`;

const One = {
  marginBottom: '1rem',
  backgroundColor: '#00ff00',
};

const Two = {
  width: '100%',
  lineHeight: '10px',
};

const Three = {
  backgroundColor: '#FFA38F',
  lineHeight: '40px',
  fontWeight: '600',
  marginBottom: '2rem',
};

function Csj() {
  return (
    <Container>
      <div style={LeftBackground}>
        <img src={LeftYellowBackground} alt="" />
      </div>
      <ImageSection>
        <img src={csj} alt="Example" />
      </ImageSection>
      <TextSection>
        <h1 style={One}>CEO 최수진</h1>
        <p style={Two}>
          <strong>
            <h1>대기업 이상의 책임감</h1>
            <h1>대기업 이하의 최저가</h1>
          </strong>
        </p>
        <br />
        <p style={Three}>
          <strong>
            최동차는 동탄뿐 아니라 상담과 계약을 원하시는 <br />
            어디라도 찾아갑니다.
          </strong>
        </p>
      </TextSection>
    </Container>
  );
}

export default Csj;
