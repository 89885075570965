import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4ueP-aBdQPhoHOT18jZNFbY2FbR0dm8c",
  authDomain: "choidongcha-a8edc.firebaseapp.com",
  databaseURL: "https://choidongcha-a8edc-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "choidongcha-a8edc",
  storageBucket: "choidongcha-a8edc.appspot.com",
  messagingSenderId: "737494847492",
  appId: "1:737494847492:web:678f606df68a827c786551",
  measurementId: "G-6YV603Q6QM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// Firebase 객체 저장
const db = getFirestore(app);


export default db;
