// src/CarCarousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarCarousel.css';

import audi_a6 from '../Assets/audi_a6.png';
import volvo_xc60 from '../Assets/volvo_xc60.png';
import bmw520i from '../Assets/bmw520i.png';
import benz_e_class from '../Assets/benz-e-class.png';

const highLight = {
  backgroundColor: '#00ff00',
};

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  gap: 30,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

function ImportedCars() {
  const cars = [
    {
      id: 1,
      model: '벤츠 E-Class',
      price: '차량가 : 73,9000,000원',
      noBond: '추기비용 0원: 월 984,510원',
      bond30: '선수금30%: 월 485,310원',
      url: benz_e_class,
    },
    {
      id: 2,
      model: 'BMW 520i',
      price: '차량가 : 68,800,000원',
      noBond: '추기비용 0원: 월 860,400원',
      bond30: '선수금30%: 월 425,360원',
      url: bmw520i,
    },
    {
      id: 3,
      model: '아우디 A6',
      price: '차량가 : 72,155,000원',
      noBond: '추기비용 0원: 월 936,000원',
      bond30: '선수금30%: 월 461,270원',
      url: audi_a6,
    },
    {
      id: 4,
      model: '볼보 XC60',
      price: '차량가 : 63,900,000',
      noBond: '추가비용 0원: 월 984,510원',
      bond30: '선수금30%: 월 485,310원',
      url: volvo_xc60,
    },
    // { id: 5, model: 'Car Model 5', price: '$28,000', url: car5 },
  ];

  return (
    <div className="carousel-container">
      <h1 className="suv" style={highLight}>
        수입차 특가 프로 모션
      </h1>

      <Slider {...settings}>
        {cars.map((car) => (
          <div key={car.id} className="car-card">
            <img src={car.url} alt={car.model} />
            <div className="car-info">
              <h3>{car.model}</h3>
              <div className="car-price">
                <p>{car.price}</p>
                <p>{car.noBond}</p>
                <p>{car.bond30}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImportedCars;
