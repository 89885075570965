import * as React from 'react';
import './Blood.css';
import RightYellowBackground from '../Assets/right-yellow-background.png';
import TotheFormButton from './TotheFormButton';
import styled from 'styled-components';
import Blood from '../Assets/blood.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1400px;
  margin: 3rem auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  

  h1 {
    font-size: 2.1rem;
    color: "#00274C",
    margin-bottom: 10px;
    line-height: 4rem;
  }

  p {
    font-size: 1.1rem;
    color: "#00274C";
    line-height: 2.2rem;
    margin-bottom: 2rem;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1080px;
    border-radius: 8px;
    box-shadow: 10px 5px 5px #00274c;
  }
`;
function Ksr() {
  return (
    <Container>
      <div className="rightBackground">
        <img src={RightYellowBackground} alt="" />
      </div>
      <TextSection>
        <div className="one">
          <h1>
            많은 견적이 낮은 가격을
            <br />
            보장하지 않습니다.
          </h1>
        </div>
        <div>
          <p>
            <strong>
              저희와 상담하시는 많은 고객님들께서 <br />
              견적을 많이 받을수록 심신이 <br />
              복잡해지는 것 같다는 말씀을 하시곤 합니다. <br />그 복잡한 마음
              최동차가 다 풀어드립니다.
            </strong>
          </p>
        </div>
        <TotheFormButton />
      </TextSection>
      <ImageSection>
        <img src={Blood} alt="Example" />
      </ImageSection>
    </Container>
  );
}

export default Ksr;
