/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import './Ksr.css';
import RightYellowBackground from '../Assets/right-yellow-background.png';
import TotheFormButton from '../Components/TotheFormButton';
import styled from 'styled-components';
import ksr from '../Assets/ksr_1.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1400px;
  margin: 1rem auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  line-height: 2rem;

  h1 {
    font-size: 2rem;
    color: "#00274C",
    margin-bottom: 1rem;
    line-height: 4rem;
  }

  p {
    font-size: 1.5rem;
    color: "#00274C",
  }
`;

const four = {
  textAlign: 'center',
  backgroundColor: '#00ff00',
  margin: '2rem auto',
  width: '15rem',
};

const ImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1080px;
    border-radius: 8px;
    box-shadow: 10px 5px 5px gray;
  }
`;

function Ksr() {
  return (
    <Container>
      <div className="rightBackground">
        <img src={RightYellowBackground} alt="" />
      </div>
      <TextSection>
        <div>
          <h1>
            신차 장기 리스의 믿을 수 있는 파트너
            <br />
            신차 장기 렌트의 최저가 보장
          </h1>

          <p>
            <strong>
              2012년 창립. <br /> 12년 된 회사.
              <br />
              매출 8천억을 달성 한 <br />
              강력한 파트너 오토다이렉트카와 <br />
              함께하는
            </strong>
          </p>
        </div>
        <div style={four}>
          <h1>최동차 입니다.</h1>
        </div>
        <TotheFormButton />
      </TextSection>
      <ImageSection>
        <img src={ksr} alt="Example" />
      </ImageSection>
    </Container>
  );
}

export default Ksr;
