import Main from './Pages/Main';
import Termsconditions from './Pages/Termsconditions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Termsconditions" element={<Termsconditions />} />
      </Routes>
    </Router>
  );
}

export default App;
